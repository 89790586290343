<template>
  <div>
    <nav-bar></nav-bar>
    <section class="resturant-main tab-content header-top">
      <div class="searchbar-item mastr-search">
        <div class="container">
          <div class="row flex-wrap">
            <!-- v-if="openTab && $auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.store_selection == 1" -->
            <div class="col-md-3 order-lg-1 order-2 p-0 mt-lg-0 mt-3">
              <div class="tb-cls">
                <ul class="nav nav-tabs border-0 me-lg-4">
                  <li class="nav-item">
                    <a href="#vendor" class="nav-link navbar-default" data-bs-toggle="tab"
                      :class="{ active: this.type == 1 }" @click="searchRestaurant()">{{ $auth.setting.restaurant_name
                      }}</a>
                  </li>
                  <li class="nav-item">
                    <a href="#item" class="nav-link navbar-default" data-bs-toggle="tab"
                      :class="{ active: this.type == 2 }" @click="searchItem(search)">{{ $t("item") }}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col order-lg-2 order-1 p-0">
              <div class="search-bar-main w-100 d-flex align-items-center position-relative">
                <i class="fas fa-search search-icon"></i>
                <input type="search" id="search" v-model="search" name="search" class="srch-itm"
                  :placeholder="$t('Search')" v-on:keyup="searByType" autocomplete="off" />
                <router-link :to="{ name: 'home' }" class="srchclose">
                  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" class="sc-64ptou-0 faawBe nukjgu-4 kztNWe"
                    @click="removeText()">
                    <g stroke-width="2" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M13 1L1 13M1 1l12 12"></path>
                    </g>
                  </svg>
                  <!-- <br><span>{{ $t('Close')}}</span> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="tab-content">
              <div id="vendor" class="tab-pane active tab-box" v-if="$auth.setting">
                <!---------Skeleton list Start-------->
                <div class="hme-res-itm mb-5" v-if="is_skel_loader">
                  <div id="resturant-item-main2" class="resturant-item-main resturant-item-skeleton">
                    <a href="javascript:void(0)" v-for="itemcount in 6" :key="itemcount">
                      <div class="resturant-item d-flex align-items-start">
                        <skeleton-loader-vue class="skel-img-cls" type="string" />
                        <div class="resturant-data">
                          <h4>
                            <skeleton-loader-vue type="string" height="20px" width="135px" />
                          </h4>
                          <div class="res-price-main mt-2 d-flex">
                            <div>
                              <skeleton-loader-vue type="string" width="44px" height="24px" />
                            </div>
                            <ul class="time-price d-flex justify-content-center mb-0 ms-3">
                              <li>
                                <skeleton-loader-vue type="string" width="44px" height="24px" />
                              </li>
                              <li>
                                <span></span><skeleton-loader-vue type="string" width="44px" height="24px" />
                              </li>
                            </ul>
                          </div>
                          <div class="offer-sec">
                            <skeleton-loader-vue type="string" width="400px" height="24px" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div v-else class="order-offers rest-not-found 4">
                  <div v-if="$auth.setting && loading && items.length == 0">
                    <p>{{ $t("No search Found") }}</p>
                  </div>
                </div>
                <!---------Skeleton list End-------->
                <div :class="'resturant-item-main' +
                  (webVendorListingLayout == '1'
                    ? ''
                    : webVendorListingLayout)
                  " id="resturant-item-main" v-if="items && items.length > 0">
                  <item v-for="item in items" :key="item.name" :item="item" :currencys="$auth.setting.currency"
                    :isPickupDelivery="isPickupDelivery"
                    :isSchedule="$auth.setting.is_enable_schedule_order_after_business_hours"
                    :webVendorLayout="webVendorListingLayout" components=""></item>
                </div>
              </div>
              <div id="item" class="tab-pane tab-box">
                <div class="menuitems-main">
                  <div class="main-content">
                    <div class="menulength">
                      <section class="srcsectin">
                        <!------------Product Skeleton Start-------------->
                        <div class="vendor-left-main text-capitalize d-flex" v-if="is_prod_skel_loadr">
                          <div class="media w-100">
                            <div class="img-cls">
                              <skeleton-loader-vue width="120" class="me-3" style="border-radius: 5px" />
                            </div>
                            <div class="media-body">
                              <h3 class="mt-0">
                                <skeleton-loader-vue type="string" width="24%" height="20px" />
                                <p>
                                  <skeleton-loader-vue width="96%" height="26px" style="
                                      border-radius: 30px !important;
                                      margin-right: 75px;
                                    " />
                                </p>
                              </h3>
                              <skeleton-loader-vue type="string" width="40%" height="20px" />
                              <div class="d-flex rating-star mt-2">
                                <skeleton-loader-vue type="string" width="24%" height="20px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="is_prod_skel_loadr" class="media-itms">
                          <div class="item_detail" right="true" v-for="prdt in 4" :key="prdt">
                            <div class="media">
                              <a href="javascript:void(0)" id="element" class="btn btn-default show-modal">
                                <div class="media-body">
                                  <h5 class="mt-0 me-5 item-name">
                                    <skeleton-loader-vue type="string" width="24%" height="20px" />
                                  </h5>
                                  <p class="mb-0 mt-2 item-descr">
                                    <skeleton-loader-vue type="string" width="80%" height="20px" />
                                  </p>
                                  <div class="prc-show">
                                    <span class="mt-2 d-inline-block item-price"><skeleton-loader-vue type="string"
                                        width="54px" height="24px" /></span>
                                  </div>
                                </div>
                              </a>
                              <div class="img-btn">
                                <div class="item-img">
                                  <skeleton-loader-vue width="120" class="img-cls" style="border-radius: 5px" />
                                </div>
                                <div class="pgnme-cls">
                                  <div>
                                    <div class="sold-de">
                                      <div class="icus">
                                        <button class="btn btn-sm">
                                          <skeleton-loader-vue type="string" width="50px" height="24px" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!------------Product Skeleton End-------------->
                        <!-- <div v-else class="order-offers rest-not-found 2">                          
                          <p v-if="loading && products.length == 0 && $auth.setting && !is_single_restaurant">{{ $t('No search Found') }}</p>
                        </div> -->
                        <div v-if="products.length > 0 && $auth.setting">
                          <div v-for="productData in products" :key="productData.restaurant_menu_id" class="proditm">
                            <div class="vendor-left-main text-capitalize d-flex" v-if="$auth.setting">
                              <div class="media w-100">
                                <div class="img-cls">
                                  <img :src="productData.icon_image" class="me-3" alt="..." />
                                </div>
                                <div class="media-body">
                                  <h3 class="mt-0">
                                    {{ productData.name }}
                                    <router-link v-if="isBrowser" :to="{
                                      name: 'restaurant',
                                      params: {
                                        restaurant_id: encodeBase64(productData.restaurant_id),
                                      },
                                    }" class="fltr-srt-cls">
                                      <p v-if="
                                        productData.restaurant_on_off ==
                                        '0' &&
                                        $auth.setting
                                          .is_enable_schedule_order_after_business_hours
                                      ">
                                        {{ $t("View More") }}
                                      </p>
                                      <p v-else-if="
                                        productData.restaurant_on_off ==
                                        '0' &&
                                        !$auth.setting
                                          .is_enable_schedule_order_after_business_hours
                                      ">
                                        {{ $t("View More") }}
                                      </p>
                                      <p v-else>{{ $t("View More") }}</p>
                                    </router-link>
                                  </h3>
                                  <p class="mb-0">
                                    {{ productData.location_address }}
                                  </p>
                                  <rating :rating="productData.avg_rating"></rating>
                                </div>
                              </div>
                            </div>
                            <div class="media-itms" :class="'media-itms-theme' +
                              (itemlayout == null || itemlayout == ''
                                ? '1'
                                : itemlayout)
                              " v-if="productData.item.length > 0">

                              <Product v-for="(item, index) in productData.item" :key="index" :item="item" :right="true"
                                :menuId="item.restaurant_menu_id" :restaurantID="productData.restaurant_id"
                                :restaurant_on_off="productData.restaurant_on_off" :pagename="pageName"
                                :slug="productData.slug" v-on:openProductDetailModal="openProductDeails" :itemlayout="itemlayout == null || itemlayout == ''
                                  ? '1'
                                  : itemlayout
                                  "></Product>
                            </div>
                            <div v-if="
                              productData.total_items > '3' && is_viewMore
                            " class="fltr-srt-cls" @click="
                              viewMore(search, productData.restaurant_id)
                              ">
                              <button class="add-to-cart btn btn-outline-warning btn-sm">
                                {{ $t("View More") }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-if="
                          $auth.setting && products.length == 0 && itemloading
                        " class="order-offers rest-not-found 3">
                          <p>{{ $t("No search Found") }}</p>
                          <div v-if="products.length > 0 && $auth.setting && is_single_restaurant" class="media-itms"
                            :class="'media-itms-theme' + ((itemlayout == null || itemlayout == '') ? '1' : itemlayout)">
                            <Product v-for="(item, i) in products" :key="i" :item="item" :right="true"
                              :menuId="item.restaurant_menu_id" :restaurantID="item.restaurant_id"
                              :restaurant_on_off="item.restaurant_on_off" :pagename="pageName" :slug="slug"
                              v-on:openProductDetailModal="openProductDeails"
                              :itemlayout="(itemlayout == null || itemlayout == '') ? '1' : itemlayout"></Product>
                          </div>
                        </div>
                        <!-- <div v-if="$auth.setting && products.length == 0 && itemloading"
                          class="order-offers rest-not-found 3">
                          <p>{{ $t('No search Found') }}</p>
                        </div> -->
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- new code -->
    <ProductDetails ref="childComponent" :restaurantID="restaurantID" :restaurant_on_off="restaurant_on_off"
      :pagename="pageName" v-if="restaurantID && restaurantID != ''"></ProductDetails>
    <!-- old code -->
    <!-- <ProductDetails ref="childComponent" :restaurantID="restaurantID" :restaurant_on_off="restaurant_on_off"
      :pagename="pageName"></ProductDetails> -->
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Item from "@/components/Item.vue";
import { mapActions, mapGetters } from "vuex";
import Product from "@/components/Product.vue";
import Rating from "@/components/Rating.vue";
import Footer from "../components/Footer.vue";
import VueSkeletonLoader from "skeleton-loader-vue";
import ProductDetails from "@/components/ProductDetails.vue";

export default {
  components: {
    NavBar,
    Item,
    Product,
    Rating,
    Footer,
    "skeleton-loader-vue": VueSkeletonLoader,
    ProductDetails,
  },
  data() {
    return {
      items: [],
      loading: true,
      filter: {
        lat: "",
        lng: "",
        city: "",
        state: "",
      },
      skip: 0,
      isStopCallingApi: false,
      search: "",
      products: [],
      imgError: "",
      openTab: false,
      pageName: "mastersearch",
      is_skel_loader: false,
      is_prod_skel_loadr: false,
      restaurant_on_off: 0,
      restaurantID: 0,
      slug: "",
      webVendorListingLayout: 1,
      itemlayout: "1",
      itemloading: true,
      type: 1,
      is_viewMore: true,
      is_single_restaurant: false,
    };
  },

  watch: {
    search(newValue) {
      this.search = this.removeSpecialCharacters(newValue);
      // this.searchItem(newValue);
    },
  },

  computed: {
    ...mapGetters("order", ["isPickupDelivery"]),
    isBrowser() {
      return typeof window !== 'undefined';
    }
  },

  mounted() {
    this.filter.lat = parseFloat(localStorage.getItem("latitude"));
    this.filter.lng = parseFloat(localStorage.getItem("longitude"));
    this.filter.city = localStorage.getItem("city");
    this.filter.state = localStorage.getItem("state");

    this.search = localStorage.getItem("searchText");
    this.searchItem(this.search);
    this.searchRestaurant(this.search);

    setTimeout(() => {
      this.itemlayout =
        this.$auth.setting && this.$auth.setting.restaurant_item_layout;
      if (this.itemlayout == "") {
        this.itemlayout = 1;
      }
      this.webVendorListingLayout = this.$auth.setting.vendor_listing_layout;
      if (
        this.webVendorListingLayout == "" ||
        this.webVendorListingLayout == undefined
      ) {
        this.webVendorListingLayout = 1;
      }
    }, 1000);
  },
  methods: {
    ...mapActions("restaurant", [
      "getSearchRestaurant",
      "getSearchRestaurantItem",
      "getItemSoldStatusList",
      "getSearchRestaurantCategoryItem",
    ]),

    removeText() {
      localStorage.removeItem("searchText");
    },
    encodeBase64(value) {
      // Ensure window.btoa is available
      return this.isBrowser ? window.btoa(value) : '';
    },

    //Search Item
    searchItem(value) {
      // if (this.$auth.setting && this.is_single_restaurant) {
      //   this.searchCategoryItem(value);
      // }
      localStorage.setItem("searchText", this.search);
      this.type = 2;
      let restData = {
        searchtext: value,
        date: "",
        startrime: "",
        vendor_id: this.$auth.getVendorId(),
        cityname: this.filter.city,
        latitude: this.filter.lat,
        skip: 0,
        state: this.filter.state,
        longitude: this.filter.lng,
        is_langauge: this.$store.state.lang.locale,
        total_record: 100,
        delivery_type_time_slots: this.isPickupDelivery,
        // restaurant_id: this.restaurantID
      };
      if (value && value.length >= 3 && value.trim() != "" && value != null) {
        this.is_prod_skel_loadr = true;
        this.products = [];
        this.getSearchRestaurantItem(restData).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.openTab = true;
            this.is_prod_skel_loadr = false;
            //this.products = data.Result;
            let temProduct = data.Result;
            let searchItemsArray = [];
            data.Result.forEach((rest) => {
              if (rest.item.length > 0) {
                let tempArray = {
                  restaurant_id: rest.restaurant_id,
                  items: [],
                };
                rest.item.forEach((item) => {
                  // tempArray.items.push({ restaurant_menu_item_id: itm.restaurant_menu_item_id, restaurant_menu_id: itm.restaurant_menu_id, quantity: itm.quantity })
                  tempArray.items.push({
                    restaurant_menu_item_id: item.restaurant_menu_item_id,
                    restaurant_menu_id: item.restaurant_menu_id,
                    quantity: item.quantity,
                    restaurant_id: tempArray.restaurant_id,
                  });
                });
                searchItemsArray.push(tempArray);
              }
              // this.products = temProduct;
            });
            let itemlistsArray = {
              vendor_id: this.$auth.getVendorId(),
              item_list: JSON.stringify(searchItemsArray),
              is_langauge: this.$i18n.locale,
            };
            this.getItemSoldStatusList(itemlistsArray).then(
              ({ Result, code }) => {
                let itemdata = Result;
                if (code == 200) {
                  this.loading = false;
                  this.openTab = true;
                  this.is_prod_skel_loadr = false;
                  itemdata.forEach((item, index) => {
                    var rIndex = temProduct.findIndex(
                      (r) => r.restaurant_id == item.restaurant_id
                    );
                    if (rIndex !== -1 && temProduct[rIndex].item.length > 0) {
                      var iIndex = temProduct[rIndex].item.findIndex(
                        (r) =>
                          r.restaurant_menu_item_id ==
                          item.restaurant_menu_item_id
                      );
                      temProduct[rIndex].item[iIndex].is_sold_out =
                        item.is_sold_out;
                      temProduct[rIndex].item[iIndex].is_customization =
                        item.is_customization;
                      temProduct[rIndex].item[iIndex].item_id =
                        item.restaurant_menu_item_id;
                      temProduct[rIndex].item[iIndex].restaurant_id =
                        item.restaurant_id;
                      temProduct[rIndex].item[iIndex].quantity = item.quantity;
                    }
                  });
                  this.is_viewMore = true;
                  this.products = temProduct;
                  this.itemloading = false;
                } else {
                  this.openTab = true;
                  this.is_prod_skel_loadr = false;
                  this.itemloading = false;
                  this.products = "";
                }
              }
            );

          } else {
            this.is_prod_skel_loadr = false;
            this.itemloading = true;
            this.openTab = true;
            this.products = "";
          }
        });
      } else {
        // this.loading = false;
        // this.products = '';
        // this.openTab = true;
        this.loading = true;
        this.openTab = false;
        this.items = "";
        // this.products = [];
      }
    },

    viewMore(value, resId) {
      localStorage.setItem("searchText", this.search);
      this.type = 2;
      let restData = {
        searchtext: value,
        date: "",
        startrime: "",
        vendor_id: this.$auth.getVendorId(),
        cityname: this.filter.city,
        latitude: this.filter.lat,
        skip: 0,
        state: this.filter.state,
        longitude: this.filter.lng,
        is_langauge: this.$store.state.lang.locale,
        total_record: 100,
        delivery_type_time_slots: this.isPickupDelivery,
        restaurant_id: resId,
      };
      if (value && value.length >= 3 && value.trim() != "" && value != null) {
        this.is_prod_skel_loadr = true;
        this.products = [];
        this.getSearchRestaurantItem(restData).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.openTab = true;
            this.is_prod_skel_loadr = false;
            //this.products = data.Result;
            let temProduct = data.Result;
            let searchItemsArray = [];
            data.Result.forEach((rest) => {
              if (rest.item.length > 0) {
                let tempArray = {
                  restaurant_id: rest.restaurant_id,
                  items: [],
                };
                rest.item.forEach((itm) => {
                  // tempArray.items.push({ restaurant_menu_item_id: itm.restaurant_menu_item_id, restaurant_menu_id: itm.restaurant_menu_id, quantity: itm.quantity })
                  tempArray.items.push({
                    restaurant_menu_item_id: itm.restaurant_menu_item_id,
                    restaurant_menu_id: itm.restaurant_menu_id,
                    quantity: itm.quantity,
                    restaurant_id: tempArray.restaurant_id,
                  });
                });
                searchItemsArray.push(tempArray);
              }
              // this.products = temProduct;
            });
            let itemlistsArray = {
              vendor_id: this.$auth.getVendorId(),
              item_list: JSON.stringify(searchItemsArray),
              is_langauge: this.$i18n.locale,
            };

            this.getItemSoldStatusList(itemlistsArray).then(
              ({ Result, code }) => {
                let itemdata = Result;
                if (code == 200) {
                  this.loading = false;
                  this.openTab = true;
                  this.is_prod_skel_loadr = false;
                  itemdata.forEach((item, index) => {
                    var rIndex = temProduct.findIndex(
                      (r) => r.restaurant_id == item.restaurant_id
                    );
                    if (rIndex !== -1 && temProduct[rIndex].item.length > 0) {
                      var iIndex = temProduct[rIndex].item.findIndex(
                        (r) =>
                          r.restaurant_menu_item_id ==
                          item.restaurant_menu_item_id
                      );
                      temProduct[rIndex].item[iIndex].is_sold_out =
                        item.is_sold_out;
                      temProduct[rIndex].item[iIndex].is_customization =
                        item.is_customization;
                      temProduct[rIndex].item[iIndex].item_id =
                        item.restaurant_menu_item_id;
                      temProduct[rIndex].item[iIndex].restaurant_id =
                        item.restaurant_id;
                      temProduct[rIndex].item[iIndex].quantity = item.quantity;
                    }
                  });
                  this.is_viewMore = false;
                  this.products = temProduct;
                  this.itemloading = false;
                } else {
                  this.openTab = true;
                  this.is_prod_skel_loadr = false;
                  this.itemloading = false;
                  this.products = "";
                }
              }
            );
          } else {
            this.is_prod_skel_loadr = false;
            this.itemloading = true;
            this.openTab = true;
            this.products = "";
          }
        });
      } else {
        // this.loading = false;
        // this.products = '';
        // this.openTab = true;
        this.loading = true;
        this.openTab = false;
        this.items = "";
        // this.products = [];
      }
    },

    searByType() {
      if (this.type == 1) {
        this.searchRestaurant(this.search);
      } else {
        this.searchItem(this.search);
      }
    },
    //Get All Search Restaurant
    searchRestaurant(value) {
      this.type = 1;
      let serchLength = this.removeSpecialCharacters(value).length;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        localStorage.setItem("searchText", this.search);
        let search = this.search;
        if (this.$auth.setting) {
          let restData = {
            searchtext: this.search,
            date: "",
            startrime: "",
            vendor_id: this.$auth.getVendorId(),
            cityname: this.filter.city,
            latitude: this.filter.lat,
            skip: 0,
            state: this.filter.state,
            longitude: this.filter.lng,
            is_langauge: this.$store.state.lang.locale,
            delivery_type_time_slots: this.isPickupDelivery,
          };
          this.items = [];
          if (
            search &&
            serchLength >= 3 &&
            search.trim() != "" &&
            search != null
          ) {
            this.is_skel_loader = true;
            //this.searchItem(search);
            //this.searchCategoryItem(search);
            this.getSearchRestaurant(restData).then((data) => {
              if (data.code == 200) {
                this.is_skel_loader = false;
                this.items = "";
                this.loading = false;
                this.openTab = true;
                this.items = data.Result;
              }
              if (data.code == 101) {
                this.is_skel_loader = false;
                this.loading = true;
                this.openTab = true;
                this.items = "";
                this.products = [];
              }
            });
          } else {
            this.loading = true;
            this.openTab = false;
            this.items = "";
            this.products = [];
          }
        }
      }, 1000);
    },

    //Search Category Item
    searchCategoryItem(value) {
      let restData = {
        searchtext: value,
        date: "",
        startrime: "",
        vendor_id: this.$auth.getVendorId(),
        cityname: this.filter.city,
        latitude: this.filter.lat,
        skip: 0,
        state: this.filter.state,
        longitude: this.filter.lng,
        is_langauge: this.$store.state.lang.locale,
        total_record: 100,
      };
      if (value && value.length >= 3 && value.trim() != "" && value != null) {
        this.is_prod_skel_loadr = true;
        this.products = [];
        this.getSearchRestaurantCategoryItem(restData).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.openTab = true;
            this.is_prod_skel_loadr = false;
            let temProduct = data.Result;
            this.slug = data.restaurant_details.slug;
            let searchItemsArray = [];
            data.Result.forEach((rest) => {
              if (rest.item.length > 0) {
                let tempArray = {
                  restaurant_id: rest.restaurant_id,
                  items: [],
                };
                rest.item.forEach((itm) => {
                  if (itm.items && itm.items.length > 0 && itm.items != null) {
                    itm.items.forEach((itmcat) => {
                      tempArray.items.push({
                        restaurant_menu_item_id: itmcat.restaurant_menu_item_id,
                        restaurant_menu_id: itmcat.restaurant_menu_id,
                      });
                    });
                  }
                });
                searchItemsArray.push(tempArray);
              }
            });
            let itemlistsArray = {
              vendor_id: this.$auth.getVendorId(),
              item_list: JSON.stringify(searchItemsArray),
              is_langauge: this.$i18n.locale,
            };
            this.getItemSoldStatusList(itemlistsArray).then(
              ({ Result, code }) => {
                let itemdata = Result;
                if (code == 200) {
                  this.loading = false;
                  this.openTab = true;
                  this.is_prod_skel_loadr = false;
                  itemdata.forEach((item, index) => {
                    var rIndex = temProduct.findIndex(
                      (r) => r.restaurant_id == item.restaurant_id
                    );
                    if (
                      temProduct[rIndex] &&
                      temProduct[rIndex].item.length > 0
                    ) {
                      temProduct[rIndex].item.forEach((itmname, inx) => {
                        itmname.items.forEach((restitem, restindx) => {
                          const result = temProduct[rIndex].item.reduce(
                            (acc, d, i) => {
                              if (acc) {
                                if (d.items) {
                                  var iIndex = temProduct[rIndex].item[
                                    i
                                  ].items.findIndex(
                                    (r) =>
                                      r.restaurant_menu_item_id ==
                                      item.restaurant_menu_item_id
                                  );
                                  if (iIndex != -1) {
                                    temProduct[rIndex].item[i].items[
                                      iIndex
                                    ].is_sold_out = item.is_sold_out;
                                    temProduct[rIndex].item[i].items[
                                      iIndex
                                    ].is_customization = item.is_customization;
                                    temProduct[rIndex].item[i].items[
                                      iIndex
                                    ].item_id = item.restaurant_menu_item_id;
                                    temProduct[rIndex].item[i].items[
                                      iIndex
                                    ].restaurant_id = item.restaurant_id;
                                    temProduct[rIndex].item[i].items[
                                      iIndex
                                    ].quantity = item.quantity;
                                  }
                                }
                              }
                              return acc.concat(temProduct[rIndex].item[i]);
                            },
                            []
                          );
                        });
                      });
                    }
                  });
                  let mainProducts = [];
                  temProduct.forEach((tmpprod, ii) => {
                    tmpprod.item.forEach((items, k) => {
                      items.items.forEach((itm, k) => {
                        mainProducts.push(itm);
                      });
                    });
                  });
                  this.products = mainProducts;
                }
              }
            );
          }
          if (data.code == 101) {
            this.is_prod_skel_loadr = false;
            this.loading = false;
            this.openTab = false;
            this.products = "";
          }
        });
      } else {
        this.loading = false;
        this.openTab = false;
        this.products = "";
      }
    },

    //ProductDetails Popup Modal Open
    openProductDeails(value) {
      this.restaurantID = value.restaurant_id;
      this.restaurant_on_off = value.restaurant_on_off;
      window.$("#item-popup").modal("show");
      window.$("#item-popup").on("shown.bs.modal", function () { });
      // window.$("#item-popup").on('shown.bs.modal', function () {
      //   window.$("#prod-details-slider").owlCarousel({
      //     loop: true,
      //     items: 1,
      //     pagination: false,
      //     navigation: true,
      //     navigationText: ["<", ">"],
      //     autoPlay: true,
      //   });
      // });
      this.$refs.childComponent.openProductModel(value.item_id);
    },

    onImgError() {
      this.imgError = false;
    },

    removeSpecialCharacters(charactersString) {
      return charactersString.replace(/[^\w\s]/gi, "");
    },
    // removeSpecialCharacters(charactersString) {
    //   if (!charactersString) return ""; // Return empty string if null or undefined
    //   return charactersString.replace(/[^\w\s]/gi, "");
    // }

  },
};
</script>
<style scoped>
input.srch-itm {
  /* border: 1px solid #555; */
  width: 100%;
  padding: 9px 4px 9px 40px;
  /* background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center; */
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.01);
}

.search-bar-main i {
  position: absolute;
  top: 48%;
  left: 15px;
  translate: 0 -50%;
  color: var(--theme-font-primary);
  opacity: 0.5;
  font-size: 15px;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  padding-left: 20px;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

.skel-img-cls {
  width: 120px;
  height: 120px;
  margin-right: 7px;
  border-radius: 5px !important;
}
</style>
